<template>
  <div v-if="showPag">
    <ul class="pagination">
      <li v-if="showPreviousButton">
        <a @click="changePage(1)">First</a>
      </li>
      <li v-if="showPreviousButton">
        <a @click="changePage(currentPage - 1)">Prev</a>
      </li>
      <li
        v-for="page in visiblePages"
        :key="page"
        :class="{ active: currentPage === page }"
      >
        <a @click="changePage(page)">{{ page }}</a>
      </li>
      <li v-if="showNextButton">
        <a @click="changePage(currentPage + 1)">Next</a>
      </li>
      <li v-if="showNextButton">
        <a @click="changePage(totalPages)">Last</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'custom-pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    totalPages: {
      required: true,
    },
    maxVisiblePages: {
      type: Number,
      default: 5,
    },
    showPag: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    visiblePages() {
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(this.maxVisiblePages / 2)
      );
      const endPage = Math.min(
        startPage + this.maxVisiblePages - 1,
        this.totalPages
      );
      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
    showPreviousButton() {
      return this.currentPage > 1;
    },
    showNextButton() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    changePage(page) {
      this.$emit('updatePage', page);
    },
  },
};
</script>

<style scoped>
.pagination {
  width: 100vw !important;
}
</style>
