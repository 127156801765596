<template>
  <div id="sort-bar" style="margin-top: 0" class="sort-bar anal">
    <GeoFilter
      :searchGeoValue="searchGeoValue"
      :geoList="geoList"
      @searchItem="geoPage"
    />
    <AppFilter
      :searchAppValue="searchAppValue"
      :appsList="appsList"
      @searchItem="appPage"
    />
    <TaskFilter
      :searchNameValue="searchNameValue"
      :titleList="titleList"
      @searchItem="namePage"
    />
    <DownloadButton
      :isLoadingFile="isLoadingFile"
      @download="downloadFile"
    />
  </div>
</template>

<script>
import GeoFilter from '@/components/UI/CustomGeoFilter.vue';
import AppFilter from '@/components/UI/CustomAppFilter.vue';
import TaskFilter from '@/components/UI/CustomTaskFilter.vue';
import DownloadButton from '@/components/UI/CustomDownloadButton.vue';

export default {
  name: 'custom-sort-bar',
  components: {
    GeoFilter,
    AppFilter,
    TaskFilter,
    DownloadButton
  },
  props: {
    searchGeoValue: { type: Array, required: true },
    geoList: { type: Array, required: true },
    searchAppValue: { type: Array, required: true },
    appsList: { type: Array, required: true },
    searchNameValue: { type: Array, required: true },
    titleList: { type: Array, required: true },
    isLoadingFile: { type: Boolean, required: true }
  },
  methods: {
    geoPage(value) {
      this.$emit("geoPage", value);
    },
    appPage(value) {
      this.$emit("appPage", value);
    },
    namePage(value) {
      this.$emit("namePage", value);
    },
    downloadFile() {
      this.$emit("downloadFile");
    }
  }
};
</script>
