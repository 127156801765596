<template>
  <div class="top__info--block">
    <div class="refresh-data__block">
      <img src="@/assets/images/refresh.svg">
      <p
        class="refresh-data__text"
        @click="updateData"
      >
        Обновить
      </p>
    </div>
    <div class="update-time__block">
      <p class="update-time__title">
        Последняя синхронизация:
      </p>
      <p class="update-time__text">
        {{ updateTime }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'custom-sync-block',
  props: {
    updateTime: {
      type: String,
    },
  },
  methods: {
    updateData() {
      this.$emit('click');
    }
  },
};
</script>

<style lang="scss" scoped>

</style>