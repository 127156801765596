<template>
  <filters-multiselect
    :placeholder="'ГЕО'"
    :multiple="true"
    :model="searchGeoValue"
    :options="geoList"
    @searchItem="geoPage"
  />
</template>

<script>
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
export default {
  name: 'custom-geofilter',
  components: {
    filtersMultiselect,
  },
  props: {
    searchGeoValue: { type: Array, required: true },
    geoList: { type: Array, required: true }
  },
  methods: {
    geoPage(value) {
      this.$emit("searchItem", value);
    }
  }
};
</script>