<template>
  <filters-multiselect
    :placeholder="'Приложение'"
    :multiple="true"
    :model="searchAppValue"
    :options="appsList"
    @searchItem="appPage"
  />
</template>

<script>
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";

export default {
  name: 'custom-app-filter',
  components: {
    filtersMultiselect,
  },
  props: {
    searchAppValue: { type: Array, required: true },
    appsList: { type: Array, required: true }
  },
  methods: {
    appPage(value) {
      this.$emit("searchItem", value);
    }
  }
};
</script>