<template>
  <div>
    <loader v-if="isLoading" />
    <main-header :login="true" />
    <div
      class="main-block custom"
      :class="{
        overflowHidden: showSeeGeoModal === true,
      }"
    >
      <div class="top__block analytics">
        <custom-table-title
          :title="title"
        />
        <custom-sync-block
          :update-time="updateTime"
          @click="updateData"
        />
      </div>
      <device-type-select
        :searchDeviceValue="searchDeviceValue"
        :chooseType="chooseType"
        :deviceList="deviceList"
        :typeList="typeList"
        @updateDevice="devicePage"
        @updateType="typePage"
      />
      <div class="first-tab">
        <div class="wrapper">
          <custom-sort-bar
            :searchGeoValue="searchGeoValue"
            :geoList="geoList"
            :searchAppValue="searchAppValue"
            :appsList="appsList"
            :searchNameValue="searchNameValue"
            :titleList="titleList"
            :isLoadingFile="isLoadingFile"
            @geoPage="geoPage"
            @appPage="appPage"
            @namePage="namePage"
            @downloadFile="downloadFile"
          />
          <div class="table__info">
            <simple-multiselect
              :label="'показывать записей:'"
              :class-name="'count__list'"
              :placeholder="'25'"
              :multiple="false"
              :model="valueEntries"
              :options="listOptions"
              @searchItem="limitPage"
            />
            <calendar-block
              :min-date="false"
              :max-date="true"
              :date-from="dateFrom"
              :date-to="dateTo"
              :full-calendar="true"
              @searchDateRange="searchDateRange"
            />
          </div>
          <table-custom
            :head-items="headItems"
            :body-items="analyticsItems"
            :total-items="totals"
            :step="1"
            :date-from="dateFrom.getDate()"
            :date-to="dateTo.getDate()"
            @goToStep="goToStep"
            @watchGeo="watchGeo"
          />
        </div>
        <div>
          <custom-pagination
            :currentPage="paginationPage_default"
            :totalPages="pagination.pages"
            :maxVisiblePages="maxVisiblePages"
            :showPag="showPag"
            @updatePage="paginationPage"
          />
          <div>
            <p v-for="item in paginatedData" :key="item">
              {{ item }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <see-geo
      v-if="showSeeGeoModal"
      :item="itemGeo"
      :send-push-id="sendPushId"
      :push-id="pushId"
      :type-analytics="$route.params.type"
      :analytics-page="false"
      :date-to="dateToFormater"
      :date-from="dateFromFormater"
      @close="showSeeGeoModal = !showSeeGeoModal"
    />
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import seeGeo from "@/components/pushPage/seeGeoAnalytics.vue";
import loader from "@/components/elements/loader.vue";
import AnalyticsTitle from "@/components/elements/analyticsTitle.vue";
import calendarBlock from "@/components/elements/calendarBlock.vue";
import tableCustom from "@/components/analytics/tableAnalytics.vue";
import { HTTP } from "@/../axios-config";
import Multiselect from "vue-multiselect";
import AnalyticsStep from "@/components/analytics/AnalyticsStep.vue";
import CustomTableTitle from "@/components/UI/CustomTableTitle.vue";
import CustomSyncBlock from "@/components/UI/CustomSyncBlock.vue";
import CustomPagination from "@/components/UI/CustomPagination.vue";
import CustomSortBar from "@/components/UI/CustomSortbar.vue";
import SimpleMultiselect from "@/components/elements/simpleMultiselect.vue";
import DeviceTypeSelect from "@/components/UI/DeviceTypeSelect.vue";
/* eslint-disable */
export default {
  name: "analyticsPage",
  components: {
    DeviceTypeSelect,
    CustomSortBar,
    CustomPagination,
    CustomSyncBlock,
    CustomTableTitle,
    Multiselect,
    AnalyticsTitle,
    MainHeader,
    seeGeo,
    loader,
    calendarBlock,
    tableCustom,
    AnalyticsStep,
    SimpleMultiselect
  },
  data() {
    return {
      headItems: [
        {name: "#",},
        {name: "Рассылка",},
        {name: "ГЕО",},
        {name: "Отпр.",},
        {name: "Дост.",},
        {name: "Ошибка.",},
        {name: "Не отпр.",},
        {name: "Кликов",},
        {name: "CTR",},
        {name: ''},
      ],
      title: '',
      typeId: 1,
      isLoadingFile: false,
      showSeeGeoModal: false,
      isLoading: false,
      dateFrom: new Date(new Date() - 172800000),
      dateTo: new Date(),
      newDate: new Date(),
      showCalendar: false,
      showPag: false,
      listOption_default: 25,
      paginationPage_default: 1,
      viewAppPage_default: "all" ,
      viewGeoPage_default: "all",
      viewNamePage_default: "all",
      itemGeo: [],
      pushId: 0,
      valueEntries: "",
      geoList: [],
      appsList: [],
      titleList: [],
      searchGeoValue: [],
      searchAppValue: [],
      searchNameValue: [],
      dateFromFormater: "",
      dateToFormater: "",
      sendPushId: "",
      pagination: [],
      listOptions: ["25", "50", "100"],
      analyticsItems: [],
      updateTime: "",
      deviceList: [],
      searchDeviceValue: [],
      deviceType: null,
      maxVisiblePages: 5,
      data: [],
      totals: null,
      chooseType: {
        name: 'Авто',
        ident: 'auto',
        id: 1,
      },
      typeList: [
        {
          name: 'Авто',
          ident: 'auto',
          id: 1,
        },
        {
          name: 'Ежедневные',
          ident: 'everyday',
          id: 2,
        },
        {
          name: 'Моментальные',
          ident: 'moment',
          id: 1,
        },
      ]
    };
  },
  computed: {
    paginatedData() {
      const startIndex =
        (this.paginationPage_default - 1) * this.listOption_default;
      const endIndex = startIndex + this.listOption_default;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    replaceSymbol(geoItems) {
      return geoItems.replace(/,/g, " ");
    },
    watchGeo(geoItems, pushId, sendPushId) {
      this.itemGeo = this.replaceSymbol(geoItems);
      this.sendPushId = sendPushId;
      this.pushId = pushId;
      this.showSeeGeoModal = true;
      window.scrollTo(0, 0);
    },
    getSettings() {
      HTTP.get(`/front/settings/`)
        .then(({ data }) => {
          this.updateTime = data.data.sync_onesignal_push;
        })
        .catch(console.error);
    },
    typePage(item) {
      this.$router.push(`/analytics/${item.ident}`);
    },
    getDeviceType() {
      HTTP.get(`/front/type/device/`)
        .then(({ data }) => {
          this.deviceList = data.data;
        })
        .catch(console.error);
    },
    devicePage(item) {
      this.deviceType = item;
      localStorage.setItem('deviceType', JSON.stringify(item));
      this.getAnalytics();
    },
    loadDevice() {
      const savedOption = JSON.parse(localStorage.getItem('deviceType'));
      const android = { id: 2, name: 'android', ident: 'android' };
      this.deviceType = savedOption || android;
      this.searchDeviceValue = this.deviceType;
      this.searchDateRange(this.dateFrom, this.dateTo);
    },
    goToStep(itemId) {
      localStorage.setItem('dateFrom', this.dateFromFormater);
      localStorage.setItem('dateTo', this.dateToFormater);
      this.$router.push(`/analytics/step/${this.$route.params.type}/${itemId}`);
    },
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getAnalytics();
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getAnalytics();
    },
    appPage(appId) {
      this.viewAppPage_default = appId.length ? appId.map(app => app.id).join(',') : "all";
      this.getAnalytics();
    },
    geoPage(geoId) {
      this.viewGeoPage_default = geoId.length ? geoId.map(geo => geo.code).join(',') : "all";
      this.getAnalytics();
    },
    namePage(nameId) {
      this.viewNamePage_default = nameId.length ? nameId.map(name => name.id).join(',') : "all";
      this.getAnalytics();
    },
    async getAnalyticsType(id) {
      this.isLoading = true;
      try {
        const { data } = await HTTP.get(`/front/analytics/push/${id}`, {
          params: {
            geo: this.viewGeoPage_default,
            apps: this.viewAppPage_default,
            pushesId: this.viewNamePage_default,
            dateFrom: this.dateFromFormater,
            dateTo: this.dateToFormater,
            page: this.paginationPage_default,
            limit: this.listOption_default,
            typeDevice: this.deviceType.ident
          }
        });
        const { filter, content, pagination, totals } = data.data;
        this.allowFilters = filter;
        this.analyticsItems = content;
        this.totals = totals;
        this.pagination = pagination || { pages: 0 };
        this.showPag = this.pagination.pages > 1;
        if (!this.titleList.length) this.titleList = filter.title;
        if (!this.geoList.length) this.geoList = filter.geo;
        if (!this.appsList.length) {
          this.appsList = filter.apps.map(({ id, name }) => ({ id, name }));
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          this.$router.push({ name: "Login" });
        }
      } finally {
        this.isLoading = false;
      }
    },
    updateData() {
      this.isLoading = true;
      HTTP.post(`/front/analytics/update`, { status: true, typeId: this.typeId })
        .then(() => {
          this.getSettings();
          this.getAnalyticsType(this.typeId);
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getAnalytics() {
      const typeMap = {
        auto: { id: 1, title: 'Авто пуш' },
        everyday: { id: 2, title: 'Ежедневный пуш' },
        moment: { id: 3, title: 'Моментальный пуш' }
      };
      const type = typeMap[this.$route.params.type];
      if (type) {
        this.getAnalyticsType(type.id);
        this.typeId = type.id;
        this.title = type.title;
      }
    },
    dateFormatter(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    searchDateRange(dateFrom, dateTo) {
      this.dateFromFormater = this.dateFormatter(dateFrom);
      this.dateToFormater = this.dateFormatter(dateTo);
      localStorage.setItem('dateFrom', this.dateFromFormater);
      localStorage.setItem('dateTo', this.dateToFormater);
      this.showCalendar = false;
      this.getAnalytics();
    },
    downloadFile() {
      this.isLoadingFile = true;
      const baseUrl = window.location.origin === "https://purnis.xyz" ? "https://api.purnis.xyz" : "https://api-stage.purnis.xyz";
      HTTP.get(`/front/analytics/download/step1/${this.typeId}`, {
        params: {
          geo: this.viewGeoPage_default,
          apps: this.viewAppPage_default,
          pushesId: this.viewNamePage_default,
          dateFrom: this.dateFromFormater,
          dateTo: this.dateToFormater,
          typeDevice: this.deviceType
        }
      })
        .then(({ data }) => {
          const link = document.createElement('a');
          link.href = baseUrl + data.data.url;
          link.download = 'download';
          link.click();
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
          }
        })
        .finally(() => {
          this.isLoadingFile = false;
        });
    }
  },
  beforeMount() {

  },
  mounted() {
    this.dateTo = localStorage['dateTo'] ? new Date(localStorage['dateTo']) : this.dateTo;
    this.dateFrom = localStorage['dateFrom'] ? new Date(localStorage['dateFrom']) : this.dateFrom;
    this.getDeviceType();
    this.loadDevice();
    this.getSettings();
    this.getAnalytics();
  },
  watch: {
    $route(to, from) {
      this.getAnalytics();
    }
  },
};

/* eslint-disable */
</script>

<style lang="scss" scoped>
.top__block {
  flex-direction: column;
}
.wrapper {
  overflow-x: hidden;
  min-height: 500px;
}
#sort-bar.sort {
  justify-content: start;
  div {
    margin-right: 25px;
  }
}

.first-tab {
  height: 540px;
  padding-bottom: 50px;
}
.table {
  overflow-y: scroll;

  th {
    text-transform: uppercase;
  }
  td {
    padding: auto 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 100px;
    white-space: break-spaces;
  }
}
.modal__label {
  width: 175px;
  white-space: nowrap;
}
.postback__info--calendar {
  margin-right: 0;
}
.flex_filter {
  display: flex;
  align-items: baseline;
  width: 100% !important;
  justify-content: inherit;
}
.two_input .multiselect__select:before {
  right: -105px !important;
  top: -12px !important;
}
</style>
