<template>
  <div>
    <loader v-if="isLoading" />
    <main-header :login="true" />
    <div class="main-block custom">
      <div class="top__block analytics">
        <h1>
          <analytics-title
            :type="$route.params.type"
            :title="''"
          />
          &nbsp;ID: {{ $route.params.id }}
        </h1>
        <div class="top__info--block">
          <div class="breadcrumbs__block">
            <router-link
              :to="{ path: `/analytics/${$route.params.type}` }"
              class="breadcrumbs__first"
            >
              Общая статистика /
            </router-link>
            <router-link
              :to="{
                path: `/analytics/step/${$route.params.type}/${idStep1}`,
              }"
              class="breadcrumbs__first"
            >
              {{ name }} /
            </router-link>
            <router-link
              :to="{
                path: `/analytics/${$route.params.type}/${$route.params.dateTo}/${$route.params.dateFrom}/${$route.params.idPush}`,
              }"
              class="breadcrumbs__first"
            >
              {{ geoName }} /
            </router-link>
            <p class="breadcrumbs__this">
              {{ geoItem }}
            </p>
          </div>
        </div>
      </div>
      <div class="first-tab">
        <div class="wrapper">
          <div
            id="sort-bar"
            style="margin-top: 0px"
            class="sort-bar anal"
          >
            <filters-multiselect
              :placeholder="'Приложение'"
              :multiple="true"
              :model="searchAppValue"
              :options="appsList"
              @searchItem="appPage"
            />
            <button
              :class="{ active: isLoadingFile }"
              class="download__csv"
              @click="downloadFile"
            >
              <loader v-if="isLoadingFile" />
              <span v-if="!isLoadingFile">Скачать XLSX</span>
            </button>
          </div>
          <div class="table__info">
            <simple-multiselect
              :label="'показывать записей:'"
              :class-name="'count__list'"
              :placeholder="'25'"
              :multiple="false"
              :model="valueEntries"
              :options="listOptions"
              @searchItem="limitPage"
            />
          </div>
          <table-custom
            :head-items="headItems"
            :body-items="analyticsItems"
            :total-items="this.totals"
            :step="4"
            :date-from="$route.params.dateTo.split('-')[2]"
            :date-to="$route.params.dateFrom.split('-')[2]"
          />
        </div>
        <ul
          v-if="showPag"
          class="pagination"
        >
          <li
            v-for="pag in pagination.pages"
            :key="pag"
            :class="{ active: paginationPage_default == pag }"
            @click="paginationPage(pag)"
          >
            {{ pag }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import AnalyticsTitle from "@/components/elements/analyticsTitle.vue";
import tableCustom from "@/components/analytics/tableAnalytics.vue";
import simpleMultiselect from "@/components/elements/simpleMultiselect.vue";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import loader from "@/components/elements/loader.vue";
import { HTTP } from "@/../axios-config";

/* eslint-disable */
export default {
  name: "AnalyticsGeoApp",
  components: {
    AnalyticsTitle,
    MainHeader,
    tableCustom,
    simpleMultiselect,
    filtersMultiselect,
    loader,
  },
  data() {
    return {
      isLoadingFile: false,
      isLoading: false,
      showPag: false,
      listOption_default: 25,
      paginationPage_default: 1,
      viewAppPage_default: "all",
      valueEntries: "",
      appsList: [],
      searchAppValue: "",
      listOptions: ["25", "50", "100"],
      analyticsItems: [],
      pagination: [],
      headItems: [
        { name: "#" },
        { name: "Приложение" },
        // { name: "Заголовок" },
        // { name: "Текст" },
        {name: "Отпр.",},
        {name: "Дост.",},
        {name: "Ошибка.",},
        {name: "Не отпр.",},
        { name: "Кликов" },
        { name: "CTR" },

      ],
      name: '',
      geoName: '',
      geoItem: '',
      idStep1: '',
      totals: null,
    };
  },
  created() {
    this.name = localStorage['companyName'];
    this.geoName = localStorage['geoName'];
    this.geoItem = localStorage['geoItem'];
    this.idStep1 = localStorage['id'];
  },
  methods: {
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getAnalytics();
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getAnalytics();
    },
    appPage(appId) {
      if (appId.length < 1) {
        this.viewAppPage_default = "all";
      } else {
        this.viewAppPage_default = appId[0].id;
        for (let i = 1; i < appId.length; i++) {
          this.viewAppPage_default += `,${appId[i].id}`;
        }
      }
      this.getAnalytics();
    },
    getAnalytics() {
      window.onpopstate = function () {
        history.history.pushState(null, null, `/analytics/${$route.params.type}/${$route.params.dateTo}/${$route.params.dateFrom}/${$route.params.id}-${$route.params.sendId}`);
      };
      this.isLoading = true;
      this.analyticsItems = [];
      this.pagination = [];
      HTTP.get(
        `/front/analytics/push/step3/${this.$route.params.idPush}/${this.$route.params.geo}?app=${this.viewAppPage_default}&limit=${this.listOption_default}&page=${this.paginationPage_default}&dateFrom=${this.$route.params.dateTo}&dateTo=${this.$route.params.dateFrom}`
      )
        .then((response) => {
          let data = response.data.data;
          let filterItems = data.filter;
          let appsListTemp = filterItems.app;

          this.pagination = data.pagination;
          this.analyticsItems = data.content;
          this.totals = data.totals;

          if (this.appsList.length === 0) {
            for (let i = 0; i < appsListTemp.length; i++) {
              this.appsList.push({
                id: filterItems.app[i].id,
                name: filterItems.app[i].name,
              });
            }
          }

          this.showPag = this.pagination.pages > 1 ? true : false;
          this.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    downloadFile() {
      const vm = this;
      this.isLoadingFile = true;
      HTTP.get(
        `/front/analytics/download/step3/${this.$route.params.id}/${this.$route.params.geo}?app=${this.viewAppPage_default}&dateFrom=${this.$route.params.dateTo}&dateTo=${this.$route.params.dateFrom}`
      )
        .then((response) => {
          let data = response.data.data;
          let link = data.url;

          let linkDownload = document.createElement("a");
          linkDownload.setAttribute("href", link);
          linkDownload.setAttribute("download", "download");
          onload = linkDownload.click();

          this.isLoadingFile = false;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
  },
  async mounted() {
    this.getAnalytics();
  },
};
/* eslint-disable */
</script>
