<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "app",
};
</script>

<style lang="scss">
@font-face {
  font-family: "HalvarR";
  src: local("HalvarR"),
   url(./assets/fonts/Halvar/HalvarBreit-Rg.ttf) format("truetype");
}
/* width */
::-webkit-scrollbar {
    width: 5px!important;
}
input {
  font-family: 'HalvarR';
}
/* Track */
::-webkit-scrollbar-track {
    background: #161616;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #242424; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #2e426b;
  background: #161616!important;
  overflow-x: hidden;
  
  @media only screen and (max-width: 767px) {
    overflow: unset!important;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.hide {
  display: none!important;;
}
button {
  cursor: pointer;
}
.invalid-feedback {
  position: absolute!important;
  color: #ff2a00!important;
  font-family: "HalvarR"!important;
}
.is-invalid {
  border: 2px solid #ff2a00!important;
}
.vmt__nav__prev,
.vmt__nav__next {
  display: none!important;
}

.vmt__nav__item {
  @media only screen and (max-width: 767px) {
    width: 50%!important;
  }
}

.scroll_container {
  @media only screen and (max-width: 767px) {
    overflow-x: scroll!important;
  }
}



.sort-bar.anal {
  justify-content: left !important;

  div {
    margin-right: 15px;
    @media (max-width: 767px) {
      margin-right: unset;
    }
  }

  .multiselect__tags {
    margin-top: 7px !important;
  }

  .multiselect__select {
    min-width: 80px !important;
    margin-top: -12px;
  }
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: unset !important;
  right: -20px;
  top: 23px;
  padding: 4px 8px;
  text-align: center;
  display: flex !important;
  transition: transform 0.2s ease;
  align-items: center;
  justify-content: center;
}
.tabs__content {
  overflow: unset !important;
}
.modal__input {
  &.postback {
    padding: 12px 24px;
    color: #e2e2e2;
    max-width: 175px;
  }
}

.save__btn {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding: 11px 45px;
  border: none;
  margin-top: 32px;
}

.postback {
  &--title {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #e2e2e2;
    margin-top: 32px;
  }

  &--url {
    color: #7c7c7c;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    &--items {
      display: flex;
      margin-right: 0px;
      width: 40%;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      &:nth-child(2n) {
        justify-content: end;
      }
    }

    &--title {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #e2e2e2;
      opacity: 0.5;
      white-space: nowrap;
      margin-right: 10px;
    }

    &--number,
    &--counter {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #e2e2e2;
      white-space: nowrap;
    }

    &--calendar {
      width: 30px;
      height: 30px;
      background: rgba(204, 206, 221, 0.1);
      border-radius: 6px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-left: auto;
      margin-right: 32px;
      transition: 0.5s;
    }
  }
}

.offer__create {
  h2 {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #e2e2e2;
    margin: 46px 0 38px 0;
  }

  input {
    max-width: 750px;
  }

  button {
    margin-bottom: 65px;
  }
}
.count__list {
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    margin-right: 11px;
  }
}
.count__multiselect {
  width: 50px !important;
  .multiselect__tags {
    margin-top: 15px !important;
    border: none !important;
  }
  .multiselect__select:before {
    top: 100% !important;
  }
}
.icon.calendar {
  background: url(@/assets/images/calendar.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  width: 16px;
  height: 16px;
}
.table {
  &__info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__calendar {
    display: flex;
    align-items: center;
    //width: 210px;
    p {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #e2e2e2;
    }
  }
}
.csv__btn {
  color: #ff2a00;
  background: transparent;
  border: none;
  font-family: "halvarR";
  height: 42px;
  margin-top: 8px;
}
.new-csv__btn {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding: 0px 24.5px;
  border: none;
  margin-top: 8px;
  white-space: nowrap;
  height: 40px;
}
.icon.double__arrow {
  background: url(@/assets/images/double_arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  width: 16px;
  height: 16px;
}
tr.error {
  background: #fe2b003b !important;
}

tr:nth-child(even).error {
  background: #fe2b0024 !important;
}
.item__top--push {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.multiselect__tags input.multiselect__input {
  color: white;
  background: transparent;
  font-size: 14px;
  height: 34px;
  padding: 0;
  margin-top: -7px;
  display: inline-block;
}

.multiselect__tags input.multiselect__input::placeholder {
  color: #ffffff90;
}

.download__csv {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #fff;
  padding: 11px 30px;
  border: none;
  cursor: pointer;
  margin-top: 7px;
  height: 39px;
  margin-left: auto;
  //margin-right: 10px;

  @media (max-width: 767px) {
    width: 100%;
  }

  &.serch__btn {
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: auto;
    display: flex;
    margin-top: 0px;

    @media (max-width: 767px) {
      width: 87%;
      justify-content: center;
    }
  }

  &.active {
    padding: 0;
    max-width: 184px;
    .vld-overlay {
      min-width: 184px !important;
      width: 184px !important;
      height: 39px !important;
      margin-top: -20px !important;
    }

    .vld-icon {
      position: absolute !important;
      left: -60px !important;

      svg {
        width: 32px !important;
      }
    }
  }
}

.table__calendar {
  margin-right: 0px;

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
}

.table__info {
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;

    div:not(.vc-title):not(.vc-header):not(.vc-arrow) {
      width: 100%;
    }
  }
}

.flex__block {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.calendar {
  &__modal {
    position: absolute;
    bottom: -360px !important;
    right: 10px;
    z-index: 1;
    display: block !important;
    gap: 10px;
    background: #242424;
    border-radius: 8px;

    @media (max-width: 767px) {
      width: 315px !important;
    }
  }

  &__item {
    background: #242424;
    border-radius: 10px;
    p {
      font-family: HalvarR;
      margin-bottom: 0;
      margin-left: 20px;
      color: #fff;
    }
  }
}

.refresh-data {
  &__block {
    display: flex;
    margin-top: -16px;
  }

  &__text {
    font-family: 'HalvarR';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    text-decoration-line: underline;
    color: #E2E2E2;
    margin-left: 12px;
    cursor: pointer;
  }
}

.update-time {
  &__block {
    display: flex;

    p {
      font-family: 'HalvarR';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 130%;
      margin-top: 0;
    }
  }

  &__title {
    color: #7c7c7c;
  }

  &__text {
    color: #fff;
    margin-left: 12px;
  }
}
.relative__block {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 3px 0 5px;
  }

  .hover__block {
    position: relative;

    &:hover {
      cursor: pointer;

      .title__span {
        opacity: 1;
      }
    }
  }

  .title__span {
    opacity: 0;
    position: absolute;
    background: #ff2a00;
    color: white;
    font-family: HalvarR;
    font-size: 12px;
    top: 10px;
    left: 0px;
    transition: 0.3s;
    border-radius: 2px;
    padding: 2px 4px;
  }
}

.breadcrumbs {
  &__block {
    display: flex;
    margin-top: -20px;
    align-items: baseline;

    @media (max-width: 767px) {
      margin-top: 0;
      flex-direction: column;
    }
  }

  &__first {
    margin-right: 5px;
    font-family: HalvarR;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    margin-top: 15px;
    text-decoration: none;
  }

  &__this {
    font-family: HalvarR;
    font-size: 15px;
    color: #ff2a00;
  }
}

.top__block.analytics {
  flex-direction: column;
}

.next__step {
  cursor: pointer;
}
h1 {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.type-device {
  display: flex;
  gap: 20px;
}


</style>

<style scoped>
.main-container {
  min-height: calc(100vh - 70px);
}
</style>
