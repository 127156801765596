<template>
  <div class="type-device">
    <multiselect
      v-model="selectedDevice"
      class="modal__select--single"
      :multiple="false"
      track-by="name"
      label="name"
      :options="deviceList"
      :searchable="true"
      :allow-empty="false"
      :show-labels="false"
      @input="updateDevice"
    />
    <multiselect
      v-model="selectedType"
      class="modal__select--single"
      :multiple="false"
      track-by="name"
      label="name"
      :options="typeList"
      :searchable="true"
      :allow-empty="false"
      :show-labels="false"
      @input="updateType"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: 'device-type-select',
  components: {
    Multiselect
  },
  props: {
    searchDeviceValue: { required: true },
    chooseType: { type: Object, required: true },
    deviceList: { type: Array, required: true },
    typeList: { type: Array, required: true }
  },
  data() {
    return {
      selectedDevice: this.searchDeviceValue,
      selectedType: this.chooseType
    };
  },
  methods: {
    updateDevice(value) {
      this.$emit("updateDevice", value);
    },
    updateType(value) {
      this.$emit("updateType", value);
    }
  },
  watch: {
    searchDeviceValue(newVal) {
      this.selectedDevice = newVal;
    },
    chooseType(newVal) {
      this.selectedType = newVal;
    }
  }
};
</script>
