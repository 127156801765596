<template>
  <filters-multiselect
    :placeholder="'Задание'"
    :multiple="true"
    :model="searchNameValue"
    :options="titleList"
    @searchItem="namePage"
  />
</template>

<script>
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";

export default {
  name: 'custom-task-filter',
  components: {
    filtersMultiselect,
  },
  props: {
    searchNameValue: { type: Array, required: true },
    titleList: { type: Array, required: true }
  },
  methods: {
    namePage(value) {
      this.$emit("searchItem", value);
    }
  }
};
</script>