<template>
  <div>
    <loader v-if="isLoading" />
    <main-header :login="true" />
    <div
      class="main-block custom"
      :class="{
        overflowHidden: showSeeGeoModal === true,
      }"
    >
      <div class="top__block analytics">
        <h1>
          {{ title }}
          <analytics-title
            :type="$route.params.type"
            :title="''"
          />
        </h1>
        <div class="top__info--block">
          <div class="breadcrumbs__block">
            <router-link
              :to="{
                path: `/analytics/${$route.params.type}`,
              }"
              class="breadcrumbs__first"
            >
              Общая статистика /
            </router-link>
            <p class="breadcrumbs__this">
              {{ name }}
            </p>
          </div>
        </div>
      </div>
<!--      <div class="type-device">-->
<!--        <multiselect-->
<!--          v-model="searchDeviceValue"-->
<!--          class="modal__select&#45;&#45;single"-->
<!--          :multiple="false"-->
<!--          track-by="name"-->
<!--          label="name"-->
<!--          :options="deviceList"-->
<!--          :searchable="true"-->
<!--          :allow-empty="false"-->
<!--          :show-labels="false"-->
<!--          @input="devicePage(searchDeviceValue)"-->
<!--        />-->
<!--      </div>-->
      <div class="first-tab">
        <div class="wrapper">
          <div
            id="sort-bar"
            style="margin-top: 0px"
            class="sort-bar anal"
          >
            <filters-multiselect
              :placeholder="'ГЕО'"
              :multiple="true"
              :model="searchGeoValue"
              :options="geoList"
              @searchItem="geoPage"
            />
            <filters-multiselect
              :placeholder="'Приложение'"
              :multiple="true"
              :model="searchAppValue"
              :options="appsList"
              @searchItem="appPage"
            />
<!--            <filters-multiselect-->
<!--              :placeholder="'Задание'"-->
<!--              :multiple="true"-->
<!--              :model="searchNameValue"-->
<!--              :options="titleList"-->
<!--              @searchItem="namePage"-->
<!--            />-->
            <button
              :class="{ active: isLoadingFile }"
              class="download__csv"
              @click="downloadFile"
            >
              <loader v-if="isLoadingFile" />
              <span v-if="!isLoadingFile">Скачать XLSX</span>
            </button>
          </div>
          <div class="table__info">
            <simple-multiselect
              :label="'показывать записей:'"
              :class-name="'count__list'"
              :placeholder="'25'"
              :multiple="false"
              :model="valueEntries"
              :options="listOptions"
              @searchItem="limitPage"
            />
          </div>
          <table-custom
            :head-items="headItems"
            :body-items="analyticsItems"
            :total-items="this.totals"
            :step="2"
            :date-from="dateFromFormatter"
            :date-to="dateToFormatter"
            @watchGeo="watchGeo"
          />
        </div>
        <div v-if="showPag">
          <ul class="pagination">
            <li v-if="showPreviousButton">
              <a @click="paginationPage(1)">First</a>
            </li>
            <li v-if="showPreviousButton">
              <a @click="paginationPage(paginationPage_default - 1)">
                Prev
              </a>
            </li>
            <li
              v-for="page in visiblePages"
              :key="page"
              :class="{ active: paginationPage_default === page }"
            >
              <a @click="paginationPage(page)">{{ page }}</a>
            </li>
            <li v-if="showNextButton">
              <a @click="paginationPage(paginationPage_default + 1)">
                Next
              </a>
            </li>
            <li v-if="showNextButton">
              <a @click="paginationPage(analyticsItems.pages)">Last</a>
            </li>
          </ul>
          <div>
            <p
              v-for="item in paginatedData"
              :key="item"
            >
              {{ item }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <see-geo
      v-if="showSeeGeoModal"
      :item="itemGeo"
      :send-push-id="sendPushId"
      :push-id="pushId"
      :type-analytics="$route.params.type"
      :analytics-page="true"
      :date-to="dateToFormatter"
      :date-from="dateFromFormatter"
      :push-name="pushName"
      @close="showSeeGeoModal = !showSeeGeoModal"
    />
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import seeGeo from "@/components/pushPage/seeGeoAnalytics.vue";
import loader from "@/components/elements/loader.vue";
import AnalyticsTitle from "@/components/elements/analyticsTitle.vue";
import calendarBlock from "@/components/elements/calendarBlock.vue";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import simpleMultiselect from "@/components/elements/simpleMultiselect.vue";
import tableCustom from "@/components/analytics/tableAnalytics.vue";
import { HTTP } from "@/../axios-config";
import Multiselect from "vue-multiselect";

/* eslint-disable */
export default {
  name: "analyticsPage",
  components: {
    Multiselect,
    AnalyticsTitle,
    MainHeader,
    seeGeo,
    loader,
    calendarBlock,
    filtersMultiselect,
    simpleMultiselect,
    tableCustom,
  },
  data() {
    return {
      headItems: [
        { name: "#", },
        { name: "Заголовок", },
        { name: "Текст", },
        // { name: "Заголовок", },
        // { name: "Текст", },
        { name: "ГЕО", },
        {name: "Отпр.",},
        {name: "Дост.",},
        {name: "Ошибка.",},
        {name: "Не отпр.",},
        { name: "Кликов", },
        { name: "CTR", },

      ],
      title: '',
      typeId: 1,
      isLoadingFile: false,
      showSeeGeoModal: false,
      isLoading: false,
      newDate: new Date(),
      showCalendar: false,
      showPag: false,
      listOption_default: 25,
      paginationPage_default: 1,
      viewAppPage_default: "all",
      viewGeoPage_default: "all",
      viewNamePage_default: "all",
      itemGeo: [],
      pushId: 0,
      valueEntries: "",
      geoList: [],
      appsList: [],
      titleList: [],
      searchGeoValue: "",
      searchAppValue: "",
      searchNameValue: "",
      dateFromFormatter: localStorage['dateFrom'],
      dateToFormatter: localStorage['dateTo'],
      sendPushId: "",
      pagination: [],
      listOptions: ["25", "50", "100"],
      analyticsItems: [],
      updateTime: "",
      deviceList: [],
      searchDeviceValue: null,
      deviceType: null,
      maxVisiblePages: 5,
      data: [],
      name: '',
      pushName: '',
      totals: null,
    };
  },
  created() {
    this.name = localStorage['companyName'];
  },
  computed: {
    visiblePages() {
      const startPage = Math.max(
        1,
        this.paginationPage_default - Math.floor(this.maxVisiblePages / 2)
      );
      const endPage = Math.min(
        startPage + this.maxVisiblePages - 1,
        this.pagination.pages
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
    showPreviousButton() {
      return this.paginationPage_default > 1;
    },
    showNextButton() {
      return this.paginationPage_default < this.pagination.pages;
    },
    paginatedData() {
      const startIndex =
        (this.paginationPage_default - 1) * this.listOption_default;
      const endIndex = startIndex + this.listOption_default;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    getSettings() {
      const vm = this;
      HTTP.get(`/front/settings/`)
        .then(response => {
          console.log('resp settings', response.data.data);
          let data = response.data.data;
          this.updateTime = data.sync_onesignal_push;
        })
        .catch(err => {
          console.error(err);
        });
    },
    replaceSymbol(geoItems) {
      let temp = "";
      let regexp = /,/gi;
      temp = geoItems.replace(regexp, " ");
      return temp;
    },
    watchGeo(geoItems, pushId, sendPushId, pushName) {
      this.itemGeo = this.replaceSymbol(geoItems);
      this.sendPushId = sendPushId;
      window.scrollTo(0, 0);
      this.showSeeGeoModal = true;
      this.pushId = pushId;
      console.log('push Id', sendPushId);
      this.pushName = pushName;
    },
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getAnalytics();
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getAnalytics();
    },
    appPage(appId) {
      if (appId.length < 1) {
        this.viewAppPage_default = "all";
      } else {
        this.viewAppPage_default = appId[0].id;
        for (let i = 1; i < appId.length; i++) {
          this.viewAppPage_default += `,${appId[i].id}`;
        }
      }
      this.getAnalytics();
    },
    geoPage(geoId) {
      if (geoId.length < 1) {
        this.viewGeoPage_default = "all";
      } else {
        this.viewGeoPage_default = geoId[0].code;
        for (let i = 1; i < geoId.length; i++) {
          this.viewGeoPage_default += `,${geoId[i].code}`;
        }
      }
      this.getAnalytics();
    },
    namePage(nameId) {
      if (nameId.length < 1) {
        this.viewNamePage_default = "all";
      } else {
        this.viewNamePage_default = nameId[0].id;
        for (let i = 1; i < nameId.length; i++) {
          this.viewAppPage_default += `,${nameId[i].id}`;
        }
      }
      this.getAnalytics();
    },
    async getAnalyticsType(id) {
      const vm = this;
      this.analyticsItems = [];
      this.pagination = [];

      this.isLoading = true;
      localStorage.setItem('id', this.$route.params.id);
      await HTTP
        .get(`/front/analytics/push/step1/${id}/${this.$route.params.id}?geo=${this.viewGeoPage_default}&apps=${this.viewAppPage_default}&pushesId=${this.viewNamePage_default}&dateFrom=${this.dateFromFormatter}&dateTo=${this.dateToFormatter}&page=${this.paginationPage_default}&limit=${this.listOption_default}&typeDevice=${this.deviceType['ident']}`)
        .then((response) => {
          let data = response.data.data;
          console.log('data step', data);
          this.allowFilters = data.filter;
          this.analyticsItems = data.content;
          this.totals = data.totals;
          this.pagination = data.pagination;

          this.showPag = this.pagination.pages > 1 ? true : false;

          let appsListTemp = this.allowFilters.apps;

          if (this.titleList.length === 0) {
            this.titleList = this.allowFilters.title;
          }

          if (this.geoList.length === 0) {
            this.geoList = this.allowFilters.geo;
          }

          if (this.appsList.length === 0) {
            for (let i = 0; i < appsListTemp.length; i++) {
              this.appsList.push({
                id: appsListTemp[i].id,
                name: appsListTemp[i].name,
              });
            }
          }

          vm.isLoading = false;
        })
        .catch(function(error) {
          console.log(error);
          vm.isLoading = false;
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            vm.$router.push({ name: "Login" });
          }
        });
    },
    updateData(typeId) {
      let vm = this;

      this.isLoading = true;
      HTTP.post(`/front/analytics/update`, {
          status: true,
          typeId: typeId
        }
      )
        .then(function() {
          vm.getSettings();
          vm.getAnalyticsType(typeId);
          vm.isLoading = false;
        })
        .catch(function(error) {
          console.log(error);

          if (error.response.status === 401) {
            localStorage.removeItem("token");
            vm.$router.push({ name: "Login" });
          }

        });
    },
    getAnalytics() {
      if (this.$route.params.type === 'auto') {
        this.getAnalyticsType(1);
        this.typeId = 1;
      }
      if (this.$route.params.type === 'everyday') {
        this.getAnalyticsType(2);
        this.typeId = 2;
      }
      if (this.$route.params.type === 'moment') {
        this.getAnalyticsType(3);
        this.typeId = 3;
      }
      return;
    },
    downloadFile() {
      const vm = this;
      this.isLoadingFile = true;

      let url = window.location.origin === "https://purnis.xyz"
        ? "https://api.purnis.xyz"
        : "https://api-stage.purnis.xyz";

      HTTP.get(
        `/front/analytics/download/step1/${this.typeId}?geo=${this.viewGeoPage_default}&apps=${this.viewAppPage_default}&pushesId=${this.viewNamePage_default}&dateFrom=${this.dateFromFormater}&dateTo=${this.dateToFormater}&typeDevice=${localStorage['deviceType'].ident}`
      )
        .then((response) => {
          let data = response.data.data;
          let link = data.url;
          let linkDownload = document.createElement('a');

          linkDownload.setAttribute('href', url + link);
          linkDownload.setAttribute('download', 'download');
          onload = linkDownload.click();

          this.isLoadingFile = false;

        })
        .catch(function(error) {
          console.log(error);

          if (error.response.status === 401) {
            localStorage.removeItem("token");
            vm.$router.push({ name: "Login" });
          }

        });
    }
  },
  mounted() {
    this.deviceType = JSON.parse(localStorage['deviceType']);
    // this.getDeviceType();

    this.getSettings();
    this.getAnalytics();
  },
  watch: {
    $route(to, from) {
      this.getAnalytics();
    }
  },
};

/* eslint-disable */
</script>

<style lang="scss" scoped>
.top__block {
  flex-direction: column;
}

.pagination {
  width: 100vw !important;
}

//.scroll_container {
//  height: 50vh !important;
//}
.wrapper {
  //overflow-x: hidden;
  min-height: 500px;
}

#sort-bar.sort {
  justify-content: start;

  div {
    margin-right: 25px;
  }
}

.first-tab {
  height: 540px;
  padding-bottom: 50px;
}

.table {
  overflow-y: scroll;

  th {
    text-transform: uppercase;
  }

  td {
    padding: auto 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 100px;
    white-space: break-spaces;
  }
}

.modal__label {
  width: 175px;
  white-space: nowrap;
}

.postback__info--calendar {
  margin-right: 0;
}

.flex_filter {
  display: flex;
  align-items: baseline;
  width: 100% !important;
  justify-content: inherit;
}

.two_input .multiselect__select:before {
  right: -105px !important;
  top: -12px !important;
}
</style>
