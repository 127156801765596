<template>
  <button
    :class="{ active: isLoadingFile }"
    class="download__csv"
    @click="downloadFile"
  >
    <loader v-if="isLoadingFile" />
    <span v-if="!isLoadingFile" style="white-space: nowrap">Скачать XLSX</span>
  </button>
</template>

<script>
import loader from "@/components/elements/loader.vue";
export default {
  name: 'custom-download-button',
  props: {
    isLoadingFile: { type: Boolean, required: true }
  },
  components: {
    loader,
  },
  methods: {
    downloadFile() {
      this.$emit("download");
    }
  }
};
</script>
