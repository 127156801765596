<template>
  <h1>
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: 'custom-table-title',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>